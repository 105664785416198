<template>
  <div>
    <p class="account-title">Сменить пароль</p>

    <b-card no-body class="account-password">
      <b-overlay
        :show="is_loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-card-body>
          <validation-observer ref="password">
            <b-form class="data-form">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Введите старый пароль"
                    label-for="current-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Текущий пароль"
                      rules="required"
                    >
                      <b-form-input
                        id="current-password"
                        placeholder="Введите старый пароль"
                        v-model="current_password"
                        type="password"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Введите новый пароль"
                    label-for="new-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      vid="Password"
                      name="Новый пароль"
                      rules="required|password"
                    >
                      <b-form-input
                        id="new-password"
                        placeholder="Введите новый пароль"
                        v-model="new_password"
                        type="password"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Повторите пароль"
                    label-for="confirm-password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Подтвердить пароль"
                      rules="required|confirmed:Password"
                    >
                      <b-form-input
                        id="confirm-password"
                        placeholder="Повторите пароль"
                        type="password"
                        v-model="confirm_password"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="d-flex">
                  <b-button
                    type="submit"
                    @click.prevent="changePassword"
                    class="ml-auto w-100"
                    pill
                    variant="primary"
                    style="height: 56px; font-size: 16px; border-radius: 16px"
                  >
                    Сменить пароль
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      current_password: "",
      new_password: "",
      confirm_password: "",

      is_loading: false,

      required,
      password,
      email,
      confirmed,
    };
  },
  methods: {
    changePassword(args) {
      this.$refs.password.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          this.$request
            .post("user.passwordChange", {
              current_password: this.current_password,
              new_password: this.new_password,
            })
            .then((result) => {
              this.is_loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  text: "Пароль от Вашего аккаунт успешно изменен.",
                  icon: "CheckCircleIcon",
                  variant: "success",
                },
              });
            })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ошибка сервера",
                  text: err.message,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });

              this.is_loading = false;
            });
        }
      });
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss">
@media screen and (max-width: 1200px) {
  .account-password {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .account-password {
    width: 50%;
  }
}
</style>
